import { TextField } from '@material-ui/core';
import React, { forwardRef, useImperativeHandle } from 'react';

const InputCom = forwardRef((props, ref) => {
  const {
    name = '',
    label,
    disabled = false,
    placeholder,
    onChangeValue,
    style,
    value,
    isShowLable = false,
    isRow = false,
    data = '',
    isNumber = false,
    isTextCenter = false,
  } = props;
  useImperativeHandle(ref, () => ({
    resetValue() {
      onChangeValueInput('');
    },
  }));
  const onChangeValueInput = (event) => {
    if (isNumber) {
      onChangeValue(event.target.value.replace(/\D/, '') || '', data);
    } else {
      onChangeValue(event.target.value || '', data);
    }
  };
  return (
    <TextField
      inputProps={{
        style: { textAlign: isTextCenter && 'center', padding: 12, borderColor: '#fff' },
      }}
      name={name}
      disabled={disabled}
      InputLabelProps={{
        shrink: false,
      }}
      style={{ width: isRow ? 100 : 220, ...style, background: 'white', borderColor: '#fff' }}
      variant="outlined"
      margin="normal"
      fullWidth
      value={value}
      type={'text'}
      placeholder={placeholder}
      onChange={(event) => onChangeValueInput(event)}
    />
  );
});

InputCom.propTypes = {};

export default InputCom;
