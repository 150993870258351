import { Box, Container, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Images } from 'assets/images';
import logoYoinvoice from 'assets/images/logoYoinvoice.png';

import { green } from '@material-ui/core/colors';

import DoneIcon from '@material-ui/icons/Done';
const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    background: '#f4f4f4',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 50,
  },
  contain: {
    width: '100%',
    background: 'white',
    padding: 30,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
}));
Footer.propTypes = {};

function Footer(props) {
  const classes = useStyles();

  return (
    <Box pt={4}>
      <Container>
        <div className={classes.root}>
          <Toolbar className={classes.contain}>
            <div style={{ width: '40%', paddingRight: '40', flexDirection: 'column', display: 'flex' }}>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
                <div>
                  <img src={logoYoinvoice} alt={'Logo BTNOVA'} style={{ width: 50, height: 50 }} />
                </div>
                <p style={{ fontSize: '13px', fontWeight: '500' }}>
                Công ty CPDVTM Việt Nam Trực tuyến - Tầng 8, Tòa nhà Galleria, 258 Nam Kỳ Khởi Nghĩa. - Phường Võ Thị Sáu - Quận 3 - TP Hồ Chí Minh.
                </p>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h5 style={{ margin: 0 }}>Copyright © 2022</h5>
                <h5 style={{ color: '#45baca', margin: 0, marginLeft: 12 }}>Công ty CPDVTM Việt Nam Trực tuyến</h5>
              </div>
            </div>
            <div
              style={{
                width: '60%',
                flexDirection: 'column',
                display: 'flex',
                alignContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <h4 style={{ margin: 6 }}>LIÊN HỆ</h4>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 12 }}>
                <div style={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
                  <div style={{ flexDirection: 'column', display: 'flex', width: '50%' }}>
                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                    <img src={Images.LogoCall} alt={'Logo BTNOVA'} style={{ width: 30, height: 30 }} />
                      <p style={{ margin: 0, fontSize: '13px', marginLeft: 6 }}>{`19006474`}</p>
                    </div>
                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: 12 }}>
                      
                    </div>
                  </div>

                  <div style={{ flexDirection: 'column', display: 'flex', width: '50%' }}>
                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                      <img src={Images.LogoFacebook} alt={'Logo BTNOVA'} style={{ width: 30, height: 30 }} />
                      <p style={{ margin: 0, fontSize: '13px', marginLeft: 6 }}>{`Yschool`}</p>
                    </div>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 12,
                      }}
                    >
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <img src={Images.LogoZalo} alt={'Logo BTNOVA'} style={{ width: 40, height: 40 }} />
                  <div style={{ marginLeft: 4 }}>
                    <div style={{ flexDirection: 'row', display: 'flex', marginTop: 4 }}>
                      <DoneIcon style={{ color: green[500], width: 18, height: 18 }} />
                      <p style={{ margin: 0, fontSize: '13px', marginLeft: 6 }}>{`Khách hàng vào ứng dụng Zalo`}</p>
                    </div>
                    <div style={{ flexDirection: 'row', display: 'flex', marginTop: 4 }}>
                      <DoneIcon style={{ color: green[500], width: 18, height: 18 }} />
                      <p style={{ margin: 0, fontSize: '13px', marginLeft: 6 }}>{`Chọn tìm bạn bè ở thanh tìm kiếm`}</p>
                    </div>
                    <div style={{ flexDirection: 'row', display: 'flex', marginTop: 4 }}>
                      <DoneIcon style={{ color: green[500], width: 18, height: 18 }} />
                      <p style={{ margin: 0, fontSize: '13px', marginLeft: 6 }}>
                        {`Nhập "Ứng dụng Yschool" và nhấn "Quan Tâm" hoặc tìm kiếm theo số điện thoại 0838201003 để được hỗ
                    trợ`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Toolbar>
        </div>
      </Container>
    </Box>
  );
}

export default Footer;
