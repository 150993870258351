export const menuHeader = [
  {
    name: 'BÁO GIÁ',
    path: '/price',
  },
  {
    name: 'TÍNH NĂNG',
    path: '/feature',
  },
  {
    name: 'THÔNG TIN',
    path: '/news',
  },
  {
    name: 'ĐĂNG KÝ ĐẠI LÝ',
    path: '/cooperate',
  },
  {
    name: 'TRA CỨU',
    path: '/search-invoice',
  },
];

export const lstPrice = [
  {
    name: 'Yo-200',
    dis: 'Gói 200 hoá đơn',
    price: '250.000',
  },
  {
    name: 'Yo-300',
    dis: 'Gói 300 hoá đơn',
    price: '379.000',
  },
  {
    name: 'Yo-500',
    dis: 'Gói 500 hoá đơn',
    price: '508.000',
  },
  {
    name: 'Yo-1000',
    dis: 'Gói 1,000 hoá đơn',
    price: '828.000',
  },
  {
    name: 'Yo-2000',
    dis: 'Gói 2,000 hoá đơn',
    price: '1.236.000',
  },
  {
    name: 'Yo-3000',
    dis: 'Gói 3,000 hoá đơn',
    price: '1.908.000',
  },
  {
    name: 'Yo-5000',
    dis: 'Gói 5,000 hoá đơn',
    price: '2.508.000',
  },

  {
    name: 'Yo-10000',
    dis: 'Gói 10,000 hoá đơn',
    price: '3.839.000',
  },
  {
    name: 'Yo-15000',
    dis: 'Gói 15,000 hoá đơn',
    price: '5.489.000',
  },
  {
    name: 'Yo-20000',
    dis: 'Gói 20,000 hoá đơn',
    price: '6.369.000',
  },
];
