// import userReducer from 'features/auth/userSlice';
// import commonReducer from 'features/dashboard/commonSlice';
// import collectionUnitReducer from 'features/data/customer/CollectionUnit/collectionUnitSlice';
// import invoiceReducer from 'features/invoice/InvoiceManagement/invoiceSlice';
const { configureStore } = require('@reduxjs/toolkit');
const rootReducer = {
  // user: userReducer,
  // common: commonReducer,
  // collectionUnit: collectionUnitReducer,
  // invoice: invoiceReducer,
};
const store = configureStore({
  reducer: rootReducer,
});

export default store;
