import React from 'react';
import { Box } from '@material-ui/core';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import SearchInvoice from './pages/SearchInvoice';
SearchFeature.propTypes = {};

function SearchFeature(props) {
  const match = useRouteMatch();
  return (
    <Box>
      <Switch>
        <Route path={match.url} exact component={SearchInvoice} />
      </Switch>
    </Box>
  );
}

export default SearchFeature;
