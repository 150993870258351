import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Images } from 'assets/images';
import imgNews from 'assets/images/imgNews.png';
import { useHistory } from 'react-router-dom';
import cskh from 'assets/images/cskh.png';
import otherfeature from 'assets/images/otherfeature.png';
import convenient from 'assets/images/convenient.png';
import customer from 'assets/images/customer.png';
import Footer from 'components/Footer';
import { FcCheckmark } from 'react-icons/fc';

const useStyles = makeStyles((theme) => ({
  styCardName: {
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
    background: '#f0f2f2',
    padding: 10,
    borderRadius: 50,
    margin: 10,
    justifyContent: 'center',
    color: '#0877bb'
  },
  styTxtTitle: {
    fontWeight: 600,
    marginTop: 0,
    color: '#00aeef',
    fontSize: 26
  },
  styTxtContent: {
    marginTop: 0
  },
  styTxtContentv1: {
    fontSize: 14,
    marginTop: 0,
    fontWeight: 600,
  },
  styTxtTitlev1: {
    fontWeight: 800,
    marginTop: 0,
    fontSize: 20
  },
  styCardBenefitv1: {
    flexDirection: 'row',
    display: 'flex',
    width: '40%',
    background: '#cdeefa',
    padding: 40,
    borderTopLeftRadius: 50,
    borderBottomRightRadius: 50,
    margin: 20,
  },
}));

function FeatureHome(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const renderAdvantages = () => (
    <div style={{ flexDirection: 'row', display: 'flex', padding: '50px 0px' }}>
      <div style={{ alignSelf: 'center' }}>
        <h1 style={{ textAlign: 'center' }}>{`TÍNH NĂNG CỦA PHẦN MỀM HOÁ ĐƠN ĐIỆN TỬ YOINVOICE`}</h1>
      </div>
      <img src={Images.background_feature} alt={'Logo BTNOVA'} style={{ width: '30%' }} />
    </div>
  );

  const renderName = () => (
    <div style={{ flexDirection: 'column', display: 'flex', margin: 20 }}>
      <div style={{ flexDirection: 'row', display: 'flex' }}>
        <div className={classes.styCardName}>
          <div style={{ paddingRight: 10 }}>
            <h3>{`KẾT NỐI VỚI MỌI PHẦN MỀM KẾ TOÁN, BÁN HÀNG VÀ PHẦN MỀM QUẢN TRỊ KHÁC`}</h3>
          </div>
        </div>
      </div>
    </div>
  );

  const renderContent = () => (
    <div style={{ flexDirection: 'column', display: 'flex', margin: 0 }}>
      <div style={{ flexDirection: 'row', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
        <div>
          <h3 className={classes.styTxtTitle}>{`NGHIỆP VỤ HÓA ĐƠN ĐIỆN TỬ`}</h3>
          <h3 className={classes.styTxtContent}>{`Đáp ứng đầy đủ nghiệp vụ theo Nghị định 119/2018/NĐ-CP, Thông tư 32/2011/TT-BTC và Thông tư 39/2014/TT-BTC`}</h3>
        </div>
      </div>
      <div style={{ flexDirection: 'row', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
        <img src={Images.img_einvoice_major} alt={'Logo BTNOVA'} style={{ width: '100%' }} />
      </div>
    </div>
  );

  const renderBenefit = () => (
    <div style={{ flexDirection: 'column', display: 'flex', margin: 20 }}>
      <div style={{ flexDirection: 'row', display: 'flex' }}>
        <div className={classes.styCardBenefitv1}>
          <div style={{ paddingRight: 10 }}>
            <h3 className={classes.styTxtTitlev1}>{`XUẤT HÓA ĐƠN HÀNG LOẠT CHO NGƯỜI MUA`}</h3>
            <p className={classes.styTxtContentv1}>
              <FcCheckmark /> {`Thuận tiện, dễ dàng, tiết kiệm thời gian công sức`}
            </p>
          </div>
          <img src={convenient} alt={'Logo BTNOVA'} style={{ width: 90, height: 90 }} />
        </div>
        <div className={classes.styCardBenefitv1}>
          <div style={{ paddingRight: 10 }}>
            <h3 className={classes.styTxtTitlev1}>{`TÍCH HỢP VỚI CÁC PHẦN MỀM KẾ TOÁN, BÁN HÀNG`}</h3>
            <p className={classes.styTxtContentv1}>
              <FcCheckmark /> {`Kết nối với các phần mềm kế toán, bán hàng phổ biến kế thừa các thông tin bán hàng để xuất hóa đơn điện tử`}
            </p>
            <p className={classes.styTxtContentv1}>
              <FcCheckmark /> {`Sẵn sàng kết nối với mọi phần mềm quản trị khác (phần mềm bệnh viện, nhà thuốc, siêu thị…) để xuất hóa đơn điện tử`}
            </p>
          </div>
          <img src={cskh} alt={'Logo BTNOVA'} style={{ width: 90, height: 90 }} />
        </div>
      </div>
      <div style={{ flexDirection: 'row', display: 'flex' }}>
        <div className={classes.styCardBenefitv1}>
          <div style={{ paddingRight: 10 }}>
            <h3 className={classes.styTxtTitlev1}>{`HỆ THỐNG PHẦN MỀM CÓ THÊM DANH MỤC QUẢN LÝ KHÁCH HÀNG, SẢN PHẨM`}</h3>
            <p className={classes.styTxtContentv1}>
              <FcCheckmark /> {`Hỗ trợ tính năng upload khách hàng và sản phẩm nhanh chóng, tiện lợi`}
            </p>
            <p className={classes.styTxtContentv1}>
              <FcCheckmark /> {`Thêm tiện ích, tính năng, có thêm sự hài lòng`}
            </p>
          </div>
          <img src={customer} alt={'Logo BTNOVA'} style={{ width: 90, height: 90 }} />
        </div>
        <div className={classes.styCardBenefitv1}>
          <div style={{ paddingRight: 10 }}>
            <h3 className={classes.styTxtTitlev1}>{`CÁC TÍNH NĂNG KHÁC`}</h3>
            <p className={classes.styTxtContentv1}>
              <FcCheckmark /> {`Tự thiết kế mẫu hóa đơn theo nhu cầu`}
            </p>
            <p className={classes.styTxtContentv1}> <FcCheckmark /> {`Phân quyền sử dụng mẫu hóa đơn theo nhu cầu`}</p>
            <p className={classes.styTxtContentv1}> <FcCheckmark /> {`Gửi tin nhắn SMS phát hành hóa đơn và nhắc nhở thanh toán`}</p>
            <p className={classes.styTxtContentv1}> <FcCheckmark /> {`Hóa đơn có mã QR code phục vụ tra cứu tiện lợi`}</p>
            <p className={classes.styTxtContentv1}> <FcCheckmark /> {`Lập biên bản xử lý hóa đơn trên phần mềm và ký điện tử (người bán, người mua)`}</p>
            <p className={classes.styTxtContentv1}> <FcCheckmark /> {`Theo dõi tình trạng hóa đơn (đã gửi, khách hàng đã xem, đã thanh toán)`}</p>
            <p className={classes.styTxtContentv1}> <FcCheckmark /> {`Các tính năng khác`}</p>
          </div>
          <img src={otherfeature} alt={'Logo BTNOVA'} style={{ width: 90, height: 90 }} />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Box pt={4} style={{ background: '#cdeefa' }}>
        <Container>{renderAdvantages()}</Container>
      </Box>
      <Box pt={4}>
        <Container>{renderName()}</Container>
      </Box>
      <Box pt={4}>
        <Container>{renderContent()}</Container>
      </Box>
      <Box pt={4}>
        <Container>{renderBenefit()}</Container>
      </Box>
      <Footer />
    </>
  );
}

FeatureHome.propTypes = {};

export default FeatureHome;
