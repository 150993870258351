import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    :root {
        --maxWidth: 1280px;
        --white: #fff;
        --lightGrey: #eee;
        --medGrey: #353535;
        --darkGrey: #1c1c1c;
        --colorF2F2F2: #f2f2f2;
        --fontSuperBig2: 2.5rem;
        --fontSuperBig1: 2rem;
        --fontBig: 1.5rem;
        --fontMed: 1.2rem;
        --fontSmall: 1rem;
        --fontSmall2: 0.9rem;

    }

    * {
        box-sizing: boder-box;
        font-family: 'Montserrat', sans-serif;
    }

    body {
        margin: 0;
        padding: 0;
        h1 {
            font-size: 2rem;
            font-weight: 600;
        }
        h3 {
            font-size: 1.1rem;
            font-weight: 600;
        }
        p {
            font-size: 1rem;
        }

    }
`;
