import React from 'react';
import { Box } from '@material-ui/core';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NewsHome from './pages/NewsHome';

function NewsFeature(props) {
  const match = useRouteMatch();
  return (
    <Box pt={4}>
      <Switch>
        <Route path={match.url} exact component={NewsHome} />
      </Switch>
    </Box>
  );
}

export default NewsFeature;
