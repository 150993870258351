import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import InputCom from 'components/InputCom';
import ButtonCom from 'components/Buttom';
import Footer from 'components/Footer';

import cskh from 'assets/images/cskh.png';
import Laptop from 'assets/images/Laptop.png';
import legal from 'assets/images/legal.png';
import savemoney from 'assets/images/save-money.png';
import smart from 'assets/images/smart.png';
import tick from 'assets/images/tick.png';
import tryow from 'assets/images/tryow.png';

const useStyles = makeStyles((theme) => ({
  styTxtContent: {
    fontSize: 14,
    marginTop: 0,
    fontWeight: 600,
  },
  styTxtTitle: {
    fontWeight: 800,
    marginTop: 0,
  },
  styCardBenefit: {
    flexDirection: 'row',
    display: 'flex',
    width: '40%',
    background: '#cdeefa',
    padding: 40,
    borderTopLeftRadius: 50,
    borderBottomRightRadius: 50,
    margin: 20,
  },
}));

function Home(props) {
  const classes = useStyles();

  const renderAdvantages = () => {
    return (
      <>
        <h2 style={{ textAlign: 'center' }}>{`ƯU ĐIỂM CỦA YOINVOICE`}</h2>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <img src={Laptop} alt={'Logo BTNOVA'} style={{ width: '50%', height: '37%' }} />
          <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
            <div style={{ flexDirection: 'column', display: 'flex' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                <img src={tick} alt={'Logo BTNOVA'} style={{ width: 45, height: 40 }} />
                <div style={{ marginLeft: 20 }}>
                  <h3 className={classes.styTxtTitle}>{`ĐẦY ĐỦ NGHIỆP VỤ`}</h3>
                  <p className={classes.styTxtContent}>
                    {`Đáp ứng đầy đủ nghiệp vụ theo Thông tư 32/2011/TT-BTC, Thông tư 39/2014/TT-BTC và sẵn sàng đáp ứng Nghị định 119/2018/NĐ-CP, Thông tư 68/2019/TT-BTC, Nghị định 123/2020/NĐ-CP`}
                  </p>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                <img src={tick} alt={'Logo BTNOVA'} style={{ width: 45, height: 40 }} />
                <div style={{ marginLeft: 20 }}>
                  <h3 className={classes.styTxtTitle}>{`AN TOÀN BẢO MẬT`}</h3>
                  <p
                    className={classes.styTxtContent}
                  >{`YOINVOICE ứng dụng đảm bảo an toàn, bảo mật và chống làm giả hóa đơn`}</p>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                <img src={tick} alt={'Logo BTNOVA'} style={{ width: 45, height: 40 }} />
                <div style={{ marginLeft: 20 }}>
                  <h3 className={classes.styTxtTitle}>{`DỄ DÀNG SỬ DỤNG, TIẾT KIỆM CHI PHÍ`}</h3>
                  <p
                    className={classes.styTxtContent}
                  >{`Xuất hóa đơn mọi lúc mọi nơi, tiết kiệm chi phí in ấn, lưu trữ và bảo quản`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderBenefit = () => {
    return (
      <>
        <h2 style={{ textAlign: 'center' }}>{`LỢI ÍCH CỦA YOINVOICE`}</h2>
        <div style={{ flexDirection: 'column', display: 'flex', margin: 20 }}>
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div className={classes.styCardBenefit}>
              <div style={{ paddingRight: 10 }}>
                <h3 className={classes.styTxtTitle}>{`TIẾT KIỆM CHI PHÍ`}</h3>
                <p className={classes.styTxtContent}>
                  {`Tiết kiệm 95% chi phí in ấn, vận chuyển, lưu trữ và bảo quản hóa đơn`}
                </p>
              </div>
              <img src={savemoney} alt={'Logo BTNOVA'} style={{ width: 90, height: 90 }} />
            </div>
            <div className={classes.styCardBenefit}>
              <div style={{ paddingRight: 10 }}>
                <h3 className={classes.styTxtTitle}>{`TƯ VẤN CHUYÊN NGHIỆP`}</h3>
                <p className={classes.styTxtContent}>
                  {`Được tư vấn và hướng dẫn sử dụng bởi đội ngũ kế toán thuế giàu kinh nghiệm`}
                </p>
              </div>
              <img src={cskh} alt={'Logo BTNOVA'} style={{ width: 90, height: 90 }} />
            </div>
          </div>
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div className={classes.styCardBenefit}>
              <div style={{ paddingRight: 10 }}>
                <h3 className={classes.styTxtTitle}>{`PHÁP LÝ`}</h3>
                <p className={classes.styTxtContent}>
                  {`YOINVOICE được cơ quan thuế đánh giá cao về chất lượng và giới thiệu sử dụng`}
                </p>
              </div>
              <img src={legal} alt={'Logo BTNOVA'} style={{ width: 90, height: 90 }} />
            </div>
            <div className={classes.styCardBenefit}>
              <div style={{ paddingRight: 10 }}>
                <h3 className={classes.styTxtTitle}>{`THÔNG MINH & LINH HOẠT`}</h3>
                <p className={classes.styTxtContent}>
                  {`Dễ dàng sử dụng, Phát hành hóa đơn mọi lúc mọi nơi, Báo cáo chi tiết thông minh`}
                </p>
              </div>
              <img src={smart} alt={'Logo BTNOVA'} style={{ width: 90, height: 90 }} />
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderTryNow = () => {
    return (
      <div style={{ flexDirection: 'row', display: 'flex', margin: 20, paddingBottom: 20 }}>
        <div style={{ width: '60%', display: 'flex', flexDirection: 'row' }}>
          <div>
            <h2>{`Dùng thử phần mềm hóa đơn hoàn toàn miễn phí`}</h2>
            <h3>{`Không giới hạn thời gian, Đầy đủ tất cả các tính năng`}</h3>
          </div>
          <img src={tryow} alt={'Logo BTNOVA'} style={{ width: 400, height: 266 }} />
        </div>
        <div style={{ width: '40%' }}>
          <InputCom
            placeholder={'Nhập Họ và tên'}
            label="Họ và tên"
            isShowLable={true}
            style={{ width: '100%', margin: 4 }}
          />
          <InputCom
            placeholder={'Nhập Email'}
            label="Email"
            isShowLable={true}
            style={{ width: '100%', margin: 4, marginTop: 8 }}
          />
          <InputCom
            placeholder={'Nhập Số điện thoại'}
            label="Số điện thoại"
            isShowLable={true}
            style={{ width: '100%', margin: 4, marginTop: 8 }}
          />
          <InputCom
            placeholder={'Nhập Mã số thuế'}
            label="Mã số thuế"
            isShowLable={true}
            style={{ width: '100%', margin: 4, marginTop: 8 }}
          />
          <ButtonCom
            fullWidth={true}
            callbackClick={() => {}}
            text="DÙNG THỬ NGAY"
            typeBTN="REMOVE"
            style={{ marginTop: 12 }}
          />
        </div>
      </div>
    );
  };
  return (
    <>
      <h1 style={{ textAlign: 'center', color: '#3cb6c8' }}>{`HÓA ĐƠN ĐIỆN TỬ YOINVOICE`}</h1>
      <Box pt={4} style={{ background: '#cdeefa' }}>
        <Container>{renderAdvantages()}</Container>
      </Box>
      <Box pt={4}>
        <Container>{renderBenefit()}</Container>
      </Box>
      <Box pt={4} style={{ background: '#cdeefa' }}>
        <Container>{renderTryNow()}</Container>
      </Box>
      <Footer />
    </>
  );
}

export default Home;
