import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ButtonCom from 'components/Buttom';
import create from 'zustand';

const useConfirmDialogStore = create((set) => ({
  title: 'Thông báo',
  message: '',
  isYesNo: false,
  labelYes: 'Xác nhận',
  labelNo: 'Xem lại',
  onSubmit: undefined,
  close: () => set({ onSubmit: undefined }),
}));
export const confirmDialog = (title, message, isYesNo, labelYes, labelNo, onSubmit) => {
  useConfirmDialogStore.setState({ title, message, isYesNo, labelYes, labelNo, onSubmit });
};
const ConfirmDialog = () => {
  const { title, message, isYesNo, labelYes, labelNo, onSubmit, close } = useConfirmDialogStore();
  return (
    <Dialog open={Boolean(onSubmit)} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography style={{ color: 'black' }}>{message}</Typography>
      </DialogContent>
      <DialogActions>
        {isYesNo && <ButtonCom style={{ margin: 20 }} text={labelNo} typeBTN="DEFAULT" callbackClick={close} />}
        <ButtonCom
          style={{ margin: 20 }}
          text={labelYes}
          typeBTN="CREATE"
          callbackClick={() => {
            if (onSubmit) {
              onSubmit();
            }
            close();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
