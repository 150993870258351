import ConfirmDialog from 'components/ConfirmDialog';
import Header from 'components/Header';
import HomeFeature from 'features/dashboard/pages/Home';
import React from 'react';
import NewsFeature from 'features/news';
import DetailNews from 'features/news/pages/DetailNews';
import PriceHome from 'features/price';
import FeatureHome from 'features/feature';
import CooperateHome from 'features/cooperate';
import SearchFeature from 'features/search';
import { Route, Switch } from 'react-router-dom';
const Home = (props) => {
  return (
    <div style={{ position: 'relative' }}>
      <Header />
      <Switch>
        <Route path="/" exact component={HomeFeature} />
        <Route path="/price" exact component={PriceHome} />
        <Route path="/cooperate" exact component={CooperateHome} />
        <Route path="/feature" exact component={FeatureHome} />
        <Route path="/news" exact component={NewsFeature} />
        <Route path="/news/detailNews" exact component={DetailNews} />
        <Route path="/search-invoice" exact component={SearchFeature} />
      </Switch>
      <ConfirmDialog />
    </div>
  );
};

export default Home;
