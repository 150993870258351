import { Box, Container, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import cskh from 'assets/images/cskh.png';
import legal from 'assets/images/legal.png';
import savemoney from 'assets/images/save-money.png';
import smart from 'assets/images/smart.png';
import { Images } from 'assets/images';
import Footer from 'components/Footer';
import Swal from 'sweetalert2';
import config from 'config/config'; // Đường dẫn có thể thay đổi tùy theo cấu trúc thư mục của bạn

const useStyles = makeStyles((theme) => ({
  styCardBenefit: {
    flexDirection: 'row',
    display: 'flex',
    width: '40%',
    background: '#cdeefa',
    padding: 40,
    borderTopLeftRadius: 50,
    borderBottomRightRadius: 50,
    margin: 20,
  },
  hedaer: {
    background: `url(${Images.background_banner_search}) `,
    backgroundRepeat: 'no-repeat',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '476px',
    marginTop: '0px',
  },
  styBtnOpt: {
    flexDirection: 'row',
    display: 'flex',
    backgroundColor: '#ff9b53',
    borderRadius: 20,
    padding: '0 12px',
    margin: '0 12px',
  },
  searchContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 'auto',
  },
  searchInput: {
    marginRight: theme.spacing(2),
    background: 'white',
    width: '50%',
  },
}));

function SearchInvoice() {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = async () => {
    const token = await loginYoInvoice();
    if (token == null) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Không lấy được token từ YoInvoice!',
      });
    } else {
      const id = await getInvoiceId(token, searchValue);
      if (id == null) {
        const errorMessage = 'Không thể kết nối đến hệ thống truy vấn hóa đơn, vui lòng thử lại sau!';
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: errorMessage,
        });
        throw new Error('Bad Request');
      } else {
        const pdfBlob = await getPDFFile(token, id);
        if (pdfBlob) {
          const correctedBlob = new Blob([pdfBlob], { type: 'application/pdf' });
          const pdfUrl = URL.createObjectURL(correctedBlob);
          console.log('pdfUrl', pdfUrl);
          const popup = window.open('', '_blank');
          if (popup) {
            popup.document.write(`
              <html>
                <head>
                  <title>Invoice PDF</title>
                </head>
                <body>
                  <embed src="${pdfUrl}" type="application/pdf" width="100%" height="100%">
                </body>
              </html>
            `);
            popup.document.close();
            popup.focus();
            console.log('popup', popup);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Không thể mở cửa sổ mới để hiển thị PDF. Vui lòng kiểm tra cài đặt trình duyệt của bạn.',
            });
          }
        }
      }
    }
  };

  const handleDownloadXML = async () => {
    console.log('Search value:', searchValue); // Added log
    const token = await loginYoInvoice();
    console.log('token', token);
    if (token == null) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Lỗi không xác định. Vui lòng thử lại sau!',
      });
    } else {
      const id = await getInvoiceId(token, searchValue);
      if (id == null) {
        const errorMessage = 'Không thể kết nối đến hệ thống truy vấn hóa đơn, vui lòng thử lại sau!';
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: errorMessage,
        });
        throw new Error('Bad Request');
      } else {
        await getXMLFile(token, id);
      }
    }
  };

  const loginYoInvoice = async () => {
    try {
      const _username = config.username;
      const _password = config.password;
      if (!_username || !_password) return null;
      const url = config.loginUrl;
      const data = {
        userId: _username,
        password: _password,
      };
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        // Handle HTTP errors
        console.error(`HTTP error! status: ${response.status}`);
        const errorResponse = await response.text();
        console.error(`Error response: ${errorResponse}`);
        return null;
      }
      const responseJson = await response.json();
      return responseJson.data.token;
    } catch (e) {
      return null;
    }
  };

  const getInvoiceId = async (token, searchValue) => {
    try {
      const url = config.invoiceUrl + `/invioice-research/${searchValue}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Authorization-Token': token,
        },
      });
      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
        const errorResponse = await response.text();
        console.error(`Error response: ${errorResponse}`);
        return null;
      }
      const responseJson = await response.json();
      return responseJson.data.id;
    } catch (e) {
      console.error(`Error response: ${e}`);
      return null;
    }
  };

  const getPDFFile = async (token, id) => {
    try {
      const url = config.invoiceUrl + `/print-view/${id}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'X-Authorization-Token': token,
        },
      });
      if (!response.ok) {
        // Handle HTTP errors
        console.error(`HTTP error! status: ${response.status}`);
        const errorResponse = await response.text();
        console.error(`Error response: ${errorResponse}`);
        return null;
      }
      const pdfBlob = await response.blob();
      console.log('Response get pdf file:', pdfBlob);
      return pdfBlob;
    } catch (e) {
      console.log(`Exception get pdf file in yoinvoice: ${e.message}`);
    }
  };

  const getXMLFile = async (token, id) => {
    try {
      const url = config.invoiceUrl + `/download-xml/${id}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'X-Authorization-Token': token,
        },
      });
      if (!response.ok) {
        // Handle HTTP errors
        console.error(`HTTP error! status: ${response.status}`);
        const errorResponse = await response.text();
        console.error(`Error response: ${errorResponse}`);
        return null;
      }
      const blob = await response.blob();
      // Tạo URL từ blob
      const blobUrl = URL.createObjectURL(blob);
      // Tạo một liên kết tạm thời và kích hoạt sự kiện click để tải file
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `invoice-${id}.xml`; // Tên file có thể tùy chỉnh
      document.body.appendChild(link); // Thêm liên kết vào body (cần thiết cho Firefox)
      link.click(); // Kích hoạt sự kiện click để tải file
      document.body.removeChild(link); // Loại bỏ liên kết khỏi DOM
    } catch (e) {
      console.log(`Exception get pdf file in yoinvoice: ${e.message}`);
    }
  };

  const renderBenefit = () => {
    return (
      <>
        <h2 style={{ textAlign: 'center' }}>{`LỢI ÍCH CỦA YOINVOICE`}</h2>
        <div style={{ flexDirection: 'column', display: 'flex', margin: 20 }}>
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div className={classes.styCardBenefit}>
              <div style={{ paddingRight: 10 }}>
                <h3 className={classes.styTxtTitle}>{`TIẾT KIỆM CHI PHÍ`}</h3>
                <p className={classes.styTxtContent}>
                  {`Tiết kiệm 95% chi phí in ấn, vận chuyển, lưu trữ và bảo quản hóa đơn`}
                </p>
              </div>
              <img src={savemoney} alt={'Logo BTNOVA'} style={{ width: 90, height: 90 }} />
            </div>
            <div className={classes.styCardBenefit}>
              <div style={{ paddingRight: 10 }}>
                <h3 className={classes.styTxtTitle}>{`TƯ VẤN CHUYÊN NGHIỆP`}</h3>
                <p className={classes.styTxtContent}>
                  {`Được tư vấn và hướng dẫn sử dụng bởi đội ngũ kế toán thuế giàu kinh nghiệm`}
                </p>
              </div>
              <img src={cskh} alt={'Logo BTNOVA'} style={{ width: 90, height: 90 }} />
            </div>
          </div>
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <div className={classes.styCardBenefit}>
              <div style={{ paddingRight: 10 }}>
                <h3 className={classes.styTxtTitle}>{`PHÁP LÝ`}</h3>
                <p className={classes.styTxtContent}>
                  {`YOINVOICE được cơ quan thuế đánh giá cao về chất lượng và giới thiệu sử dụng`}
                </p>
              </div>
              <img src={legal} alt={'Logo BTNOVA'} style={{ width: 90, height: 90 }} />
            </div>
            <div className={classes.styCardBenefit}>
              <div style={{ paddingRight: 10 }}>
                <h3 className={classes.styTxtTitle}>{`THÔNG MINH & LINH HOẠT`}</h3>
                <p className={classes.styTxtContent}>
                  {`Dễ dàng sử dụng, Phát hành hóa đơn mọi lúc mọi nơi, Báo cáo chi tiết thông minh`}
                </p>
              </div>
              <img src={smart} alt={'Logo BTNOVA'} style={{ width: 90, height: 90 }} />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Box className={classes.hedaer}>
        <Container>
          <div>
            <h1 style={{ color: 'white', textAlign: 'center' }}>{`TRA CỨU HÓA ĐƠN ĐIỆN TỬ`}</h1>
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div className={classes.styBtnOpt}>
                <p style={{ color: 'white', margin: 10 }}>{`TRA CỨU MÃ HOÁ ĐƠN`}</p>
              </div>
              <div className={classes.styBtnOpt}>
                <p style={{ color: 'white', margin: 10 }}>{`KIỂM TRA FILE XML`}</p>
              </div>
              <div className={classes.styBtnOpt}>
                <p style={{ color: 'white', margin: 10 }}>{`TRA CỨU DANH SÁCH HOÁ ĐƠN`}</p>
              </div>
            </div>
            <div style={{ marginTop: '5%' }}>
              <div className={classes.searchContainer}>
                <TextField
                  className={classes.searchInput}
                  variant="outlined"
                  placeholder="Nhập mã hóa đơn"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value.trim())}
                />
                <Button variant="contained" color="primary" onClick={handleSearch} style={{ marginRight: '20px' }}>
                  Xem file PDF
                </Button>
                <Button variant="contained" color="primary" onClick={handleDownloadXML}>
                  Tải file XML
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </Box>
      <Box pt={4}>
        <Container>{renderBenefit()}</Container>
      </Box>
      <Footer />
    </>
  );
}

export default SearchInvoice;
