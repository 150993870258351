import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Images } from 'assets/images';
import Footer from 'components/Footer';
import DoneIcon from '@material-ui/icons/Done';

import { CoverLine, TxtPaynow, TxtDis } from './Coomperate.styles';

const useStyles = makeStyles((theme) => ({
  hedaer: {
    background: `url(${Images.bg_banner_cooperate}) `,
    backgroundRepeat: 'no-repeat',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '476px',
  },
  styIconTich: {
    color: '#0c77bb',
    width: 30,
    height: 30,
  },
}));

function CooperateHome(props) {
  const classes = useStyles();

  const renderViewBanner = () => {
    return (
      <div style={{ flexDirection: 'column', display: 'flex', padding: '20px 0px', justifyContent: 'left' }}>
        <h1
          style={{ textAlign: 'left', width: '80%', fontSize: 36, lineHeight: '1.6', color: 'white', marginBottom: 0 }}
        >{`HỢP TÁC TRIỂN KHAI PHẦN MỀM HÓA ĐƠN ĐIỆN TỬ YOINVOICE`}</h1>
        <h3 style={{ color: 'white', marginBottom: 0 }}>{`Cơ hội cùng phát triển doanh nghiệp Việt Nam`}</h3>
        <div style={{ backgroundColor: '#ff9b53', borderRadius: 6, marginTop: 22, maxWidth: 300 }}>
          <TxtPaynow>{`MUA NGAY`}</TxtPaynow>
        </div>
      </div>
    );
  };

  return (
    <>
      <Box pt={4} className={classes.hedaer}>
        <Container>{renderViewBanner()}</Container>
      </Box>
      <Box pt={4} style={{ marginTop: 50 }}>
        <Container style={{ flexDirection: 'row', display: 'flex' }}>
          <img src={Images.Laptop} alt={'Logo BTNOVA'} style={{ width: '50%', height: '37%' }} />
          <div
            style={{
              paddingLeft: 30,
              backgroundColor: '#f2f9ff',
              paddingRight: 30,
            }}
          >
            <h1 style={{ color: '#3cb6c8', marginBottom: 10 }}>{`GIỚI THIỆU`}</h1>
            <h2>{`Hợp tác cộng tác viên, đại lý`}</h2>
            <h4 style={{ textAlign: 'justify' }}>
              {`Theo nghị quyết 01/ND-CP và Nghị định 119/2018/NĐ-CP của Chính phủ, đến 31/12/2019 Hà Nội, thành phố Hồ Chí Minh và các đô thị lớn phải hoàn thành triển khai hóa đơn điện tử và từ ngày 01/11/2020 tất cả các doanh nghiệp phải áp dụng hóa đơn điện tử.`}
            </h4>
            <h4 style={{ textAlign: 'justify' }}>
              {`CÔNG TY CỔ PHẦN HÓA ĐƠN ĐIỆN TỬ YOINVOICE trân trọng kính mời các Quý đối tác tham gia hợp tác triển khai phần mềm Hóa đơn điện tử YOINVOICE với lợi ích:`}
            </h4>
            <h4 style={{ textAlign: 'justify' }}>{`– Trợ giúp doanh nghiệp tuân thủ theo quy định của Chính phủ.`}</h4>
            <h4 style={{ textAlign: 'justify' }}>{`– Tăng thêm thu nhập với mức chiết khấu hấp dẫn.`}</h4>
          </div>
        </Container>
      </Box>
      <Box pt={4} style={{ marginTop: 50 }}>
        <Container style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
          <h1
            style={{ color: '#3cb6c8', marginBottom: 10, textAlign: 'center' }}
          >{`3 LÝ DO QUÝ ĐỐI TÁC NÊN HỢP TÁC CÙNG YOINVOICE`}</h1>
          <div style={{ flexDirection: 'row', display: 'flex', marginTop: 20 }}>
            <div style={{ width: '50%', padding: 20 }}>
              <h2 style={{ color: '#0c77bb' }}>{`1. YOINVOICE Phần mềm hóa đơn điện tử tốt nhất`}</h2>
              <CoverLine>
                <DoneIcon className={classes.styIconTich} />
                <TxtDis>{`Đáp ứng đầy đủ nghiệp vụ theo Nghị định 119/2018/NĐ-CP, Thông tư 32/2011/TT-BTC và Thông tư 39/2014/TT-BTC`}</TxtDis>
              </CoverLine>
              <CoverLine>
                <DoneIcon className={classes.styIconTich} />
                <TxtDis>{`Phần mềm Hóa đơn điện tử YOINVOICE là hệ thống đã được các cơ quan thuế và khách hàng đánh giá là ổn định và tiện dụng, nhiều tính năng, khả năng mở rộng, linh hoạt theo đặc thù của khách hàng tốt nhất thị trường`}</TxtDis>
              </CoverLine>
              <CoverLine>
                <DoneIcon className={classes.styIconTich} />
                <TxtDis>{`Có hơn 5.000 doanh nghiệp tin tưởng sử dụng`}</TxtDis>
              </CoverLine>
              <CoverLine>
                <DoneIcon className={classes.styIconTich} />
                <TxtDis>{`Được nhận định là giải pháp hóa đơn điện tử hàng đầu tại Việt Nam bởi khách hàng là các doanh nghiệp lớn.`}</TxtDis>
              </CoverLine>
            </div>
            <div style={{ width: '50%' }}>
              <img src={Images.background_cooperate_01} alt={'Logo BTNOVA'} style={{ width: '100%' }} />
            </div>
          </div>
          <div style={{ flexDirection: 'row', display: 'flex', marginTop: 20 }}>
            <div style={{ width: '50%' }}>
              <img src={Images.background_cooperate_02} alt={'Logo BTNOVA'} style={{ width: '100%' }} />
            </div>
            <div style={{ width: '50%', padding: 20 }}>
              <h2
                style={{ color: '#0c77bb' }}
              >{`2. YOINVOICE – Kinh nghiệm, chuyên nghiệp An toàn – Nhanh chóng – Tiện dụng`}</h2>
              <CoverLine>
                <DoneIcon className={classes.styIconTich} />
                <TxtDis>{`YOINVOICE có được những lợi thế mà các Nhà cung cấp khác không có được đó là: An Toàn – Tiện dụng – Khả năng mở rộng linh hoạt và đặc biệt luôn phù hợp và theo kịp các thay đổi của Chính sách nhà nước về Hóa đơn`}</TxtDis>
              </CoverLine>
              <CoverLine>
                <DoneIcon className={classes.styIconTich} />
                <TxtDis>{`Dịch vụ chuyên nghiệp, chăm sóc tận tâm, hỗ trợ nhiệt tình nhất 24/7`}</TxtDis>
              </CoverLine>
            </div>
          </div>
          <div style={{ flexDirection: 'row', display: 'flex', marginTop: 20 }}>
            <div style={{ width: '50%', padding: 20 }}>
              <h2 style={{ color: '#0c77bb' }}>{`3. Chính sách hợp tác tốt, chiết khấu cao`}</h2>
              <CoverLine>
                <DoneIcon className={classes.styIconTich} />
                <TxtDis>{`Được hưởng tỷ lệ chiết khấu hấp dẫn trên tổng giá trị đơn hàng`}</TxtDis>
              </CoverLine>
              <CoverLine>
                <DoneIcon className={classes.styIconTich} />
                <TxtDis>{`Được đào tạo bài bản kiến thức về sản phẩm và khách hàng`}</TxtDis>
              </CoverLine>
              <CoverLine>
                <DoneIcon className={classes.styIconTich} />
                <TxtDis>{`Được Newinvoice hỗ trợ tốt nhất 24/7, cách tư vấn hướng dẫn cho khách hàng đăng ký và sử dụng phần mềm`}</TxtDis>
              </CoverLine>
            </div>
            <div style={{ width: '50%' }}>
              <img src={Images.background_cooperate_03} alt={'Logo BTNOVA'} style={{ width: '100%' }} />
            </div>
          </div>
        </Container>
      </Box>

      <Footer />
    </>
  );
}

CooperateHome.propTypes = {};

export default CooperateHome;
